<!--
 * @Date: 2024-10-15 11:02:12
 * @LastEditTime: 2024-10-15 11:03:03
 * @Description: 
 * @FilePath: \szlsnk-user-mp\src\components\countdown\CountDownGjc.vue
-->
<template>
  <van-button
    class="code-btn"
    type="primary"
    :disabled="!!timer"
    native-type="button"
    @click="onClick"
  >
    {{ text }}
  </van-button>
</template>

<script>
  import { isPhoneNum } from '../../utils'
  import { gjcModel } from '../../api/gjcApi'
  export default {
    name: 'Countdown',
    props: {
      phoneNum: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        checked: false,
        text: '获取验证码',
        timer: null
      }
    },
    destroyed() {
      this._clearTimer()
    },
    methods: {
      onClick() {
        // console.log('click', this.timer)
        if (this.timer) {
          return
        }
        if (!this.phoneNum) {
          this.$toast({
            message: '请输入手机号!',
            position: 'top'
          })
          return
        }
        if (!isPhoneNum(this.phoneNum)) {
          this.$toast({
            message: '手机号格式不正确!',
            position: 'top'
          })
          return
        }
        this.countdown()
        this.getVerifyCode()
      },
      countdown() {
        let time = 60
        this.timer = setInterval(() => {
          if (time === 0) {
            this._clearTimer()
            this.checked = false
            this.$emit('countdown-finish')
          } else {
            this.text = `${time}秒后重试`
            this.checked = true
            time--
          }
        }, 1000)
      },

      getVerifyCode() {
        gjcModel.sendCaptchaGjc(this.phoneNum).then(() => {
          console.log('getVerifyCode success')
          this.$emit('get-code-success')
        }).catch((err) => {
          console.log('getVerifyCode fail',err)
          this._clearTimer()
        })
      },
      _clearTimer() {
        clearInterval(this.timer)
        this.timer = null
        this.text = '重新发送'
      }
    }
  }
</script>

<style scoped lang="less">
@import "../../assets/styles/variable.less";
  .code-btn{
    width: 100%;
    height: 100%;
    color: @theme-color;
    background: #FFF;
    border-color: #FFF;
  }
</style>
